.section-4 {
  display: flex;
  padding: 90px 110px;
}

.section-4 h6 {
  margin-bottom: 0px !important;
}

.section-4-text-content {
  min-width: 50%;
  max-width: 600px;
  position: relative;
}

.section-4-text-content .illustration4{
  position: absolute;
  right: -7%;
  top: 30%;
  animation: Rotation 5s linear infinite; 
}

@keyframes Rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-4-heading {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 44px;
  letter-spacing: 0.03em;
  line-height: 57px;
  margin-bottom: 24px !important;
  color: #546076;
  position: relative;
}

.section-4-heading img {
  position: absolute;
  left: 0%;
  top: 100%;
}

.extendedLine{
  width: 280px !important;
}

.section-4-subheading {
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 16.9px;
  line-height: 125%;
  letter-spacing: 0.03em;
  color: #7c7e8c;
}

.section-4-redirectBlock{
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.section-4-downloadButton{
  display: flex;
  align-items: center;
  color: #FFFFFF;
  padding: 9px 14px;
  background: #4385F5;
  border-radius: 8px;
  margin-right: 16px;
  cursor: pointer;
  width: fit-content;
}

.section-4-downloadButton img{
  margin-right: 9px;
}

.section-4-googlePlay{
  height: 42.9px;
  cursor: pointer;
}

.tab-redirect{
  display: none;
}

.section-4-animation {
  flex-grow: 1;
  /* max-width: 300px; */
  padding: 0px 80px;
  padding-left: 0px;
}




/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .section-4-text-content .illustration4{
    right: -12%;
    top: 24%;
  }
}
@media (max-width: 1140px) {
  .section-4-text-content .illustration4{
    right: -16.8%;
    top: 24%;
  }
}
@media (max-width: 1100px) {
  .section-4 {
    flex-direction: column;
    padding: 50px 10%;
    text-align: center;
    order: 1;
  }  
  .section-4-text-content {
    min-width: 100% !important;
    margin-bottom: 60px;
    order: 1;
  }
  .section-4-text-content .illustration4{
    display: none;
  }
  .section-4-heading {
    font-weight: bold;
    font-size: 32px;
    line-height: 134%;
    letter-spacing: 0.06em;
    max-width: 600px;
    margin: auto;
  }
  .section-4-subheading {
    max-width: 555px;
    margin: auto;
    font-size: 20px;
  }
  .section-4-heading img {
    left: 54%;
    top: 100%;
    width: 250px !important;
  }
  .section-4-heading br {
    display: none;
  }
  .section-4-subheading br {
    display: none;
  }
  .tab-redirect{
    display: flex;
    order: 3;
    justify-content: center;
    margin-top: 50px !important;
  }
  .web-redirect{
    display: none;
  }
  .section-4-animation {
    padding: 0 14%;
    order: 2;
  }
  .section-4-newFeature{
    margin: 0 auto 16px auto;
  }
}

@media (max-width: 677px) and (min-width: 580px) {
  .section-4-heading img{
    top: 50%;
    width: 180px !important;
    left: 60%;
  }
}

@media (max-width: 580px) {
  .section-4 {
    flex-direction: column;
    padding: 40px 5%;
    text-align: center;
    text-align: start;
  }
  .section-4-heading {
    text-align: start;
    font-size: 24px;
    padding-left: 2%;
  }
  .section-4-subheading {
    font-size: 18px;
    padding-left: 2%;
  }
  .section-4-animation {
    padding: 0 16%;
  }
  .section-4-downloadButton{
    display: none;
  }
  .section-4-newFeature{
    margin-left: 10px;
  }
  .section-4-heading img {
    left: 50%;
    top: 100%;
    width: 160px !important;
  }
}

@media (max-width: 558px) {
  .section-4-heading img {
    left: 54%;
    top: 100%;
    width: 160px !important;
  }
}

@media (max-width: 461px) {
  .section-4-heading img {
    left: 54%;
    top: 54%;
    width: 160px !important;
  }
}

@media (max-width: 397px) {
  .section-4-heading img {
    left: 0%;
    top: 100%;
    width: 160px !important;
  }
}












/* FOR MODAL */
.modal-dialog {
  display: flex;
  justify-content: center;
}

.modal .modal-content {
  padding: 24px;
  width: fit-content;
  position: relative;
  border-radius: 18px;
}

.qrModal h5 {
  font-size: 15px;
  font-weight: 600;
}

.qrModal i {
  position: absolute;
  top: 8.2%;
  right: 10%;
  cursor: pointer;
}

.qrModal .scanner {
  padding: 10px;
  border: #ccc 1.5px solid;
  border-radius: 6px;
  margin-top: 20px;
  width: fit-content;
  margin-bottom: 16px;
}

.qrModal .scanner canvas {
  height: 150px !important;
  width: 150px !important;
}

.qrModal .small {
  font-size: 12.5px;
  font-weight: 450;
  margin-bottom: 0px;
  text-align: center;
  font-family: "Gilroy";
}
