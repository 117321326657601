.section-2 {
  display: flex;
  padding: 70px 110px;
  padding-bottom: 20px;
}

.section-2 h6 {
  margin-bottom: 0px !important;
}

.section-2-text-content {
  min-width: 50%;
  max-width: 600px;
  position: relative;
}

.section-2-text-content .illustration2{
  position: absolute;
  right: 0px;
  bottom: 40%;
  animation: MoveUpDown 3.5s ease-in-out infinite; 
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 10%;
  }
  50% {
    bottom: 36%;
  }
}

.section-2-newFeature {
  background-color: #33d67b;
  background: #33d67b;
  border-radius: 4px;
  width: fit-content;
  color: white;
  padding: 5.2px 9px;
  display: flex;
  align-items: center;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 12.9px;
  line-height: 17px;
  letter-spacing: 0.03em;
  margin-bottom: 8px;
}

.section-2-newFeature img {
  margin-right: 7.2px;
  width: 18.3px;
  height: 17.5px;
}

.section-2-heading {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 44px;
  letter-spacing: 0.03em;
  line-height: 57px;
  margin-bottom: 24px !important;
  color: #546076;
  position: relative;
}

.section-2-heading img {
  position: absolute;
  left: 0%;
  top: 100%;
}

.back-line {
  width: 150px !important;
}

.section-2-subheading {
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 16.9px;
  line-height: 125%;
  letter-spacing: 0.03em;
  color: #7c7e8c;
}

.section-2-animation {
  flex-grow: 1;
  /* max-width: 300px; */
  padding: 0px 70px;
  transform: translateY(-14%);
}

/* MEDIA QUERIES */
@media (max-width: 1167px) {
  .section-2-text-content .illustration2{
    top: 10%;
  }
}
@media (max-width: 1100px) {
  .section-2 {
    flex-direction: column;
    padding: 50px 10%;
    text-align: center;
  }  
  .section-2-text-content {
    min-width: 100% !important;
    margin-bottom: 10px;
    order: 1;
  }
  .section-2-text-content .illustration2{
    display: none;
  }
  .section-2-heading {
    font-weight: bold;
    font-size: 32px;
    line-height: 134%;
    letter-spacing: 0.06em;
    max-width: 600px;
    margin: auto;
  }
  .section-2-subheading {
    max-width: 555px;
    margin: auto;
    font-size: 20px;
  }
  .section-2-heading img {
    left: 54%;
    top: 100%;
  }
  .section-2-heading br {
    display: none;
  }
  .section-2-subheading br {
    display: none;
  }
  .section-2-animation {
    padding: 0 20%;
    order: 2;
    transform: translateY(-10%);
  }
  .section-2-newFeature{
    margin: 0 auto 16px auto;
  }
}

@media (max-width: 629px) and (min-width: 580px){
  .section-2-heading img{
    left: 64%;
    top: 100%;
  }
}

@media (max-width: 580px) {
  .section-2 {
    flex-direction: column;
    padding: 40px 5%;
    text-align: center;
    text-align: start;
  }
  .section-2-heading {
    text-align: start;
    font-size: 24px;
    padding-left: 2%;
  }
  .section-2-subheading {
    font-size: 18px;
    padding-left: 2%;
  }
  .section-2-animation {
    padding: 0 20%;
    transform: translateY(-10%);
  }
  .section-2-newFeature{
    margin-left: 10px;
  }
  .section-2-heading img {
    left: 14%;
    top: 100%;
    width: 120px !important;
  }
}

@media (max-width: 533px) {
  .section-2-heading img {
    left: 34%;
    top: 100%;
    width: 120px !important;
  }
}

@media (max-width: 450px) {
  .section-2-heading img {
    left: 40%;
    top: 100%;
    width: 120px !important;
  }
}

@media (max-width: 428px) {
  .section-2-heading img {
    left: 62.8%;
    top: 100%;
    width: 120px !important;
  }
}

@media (max-width: 392px) {
  .section-2-heading img {
    left: 0%;
    top: 100%;
    width: 120px !important;
  }
}
