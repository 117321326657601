.pagination {
  margin-bottom: 2rem !important;
}

.pagination,
.ant-pagination,
.ant-pagination-options-quick-jumper {
  font-family: Gilroy !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.03em !important;
}
.ant-pagination-item {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.ant-pagination-item,
.ant-pagination-next button,
.ant-pagination-prev button {
  text-decoration: none !important;
  color: #546076 !important;
  border-color: white !important;
  border-radius: 8px !important;
  border: 2px solid transparent !important;
  background: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 26, 90, 0.05) !important;
}
.ant-pagination-next {
  margin-left: 0.5rem !important;
}
.ant-pagination-prev {
  margin-right: 0.5rem !important;
}

.ant-pagination-next button,
.ant-pagination-prev button {
  margin: 0 auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-pagination-item:hover {
  box-shadow: 0px 1px 4px rgba(0, 26, 90, 0.05) !important;
  border: 2px solid #e5e8ef !important;
  color: #546076 !important;
  font-size: 16px !important;
  text-align: center !important;
  transition: none !important;
}
.ant-pagination-item a {
  color: #546076 !important;
}

.ant-pagination-item-active,
.ant-pagination-item:focus-visible a {
  color: white !important;
  background: #4385f5 !important;
  font-size: 16px !important;
}

.ant-pagination-item-active a {
  color: white !important;
}


@media(max-width:570px){
  .ant-pagination.mini .ant-pagination-jump-prev, .ant-pagination.mini .ant-pagination-jump-next {
    min-width:35px !important;
    line-height:24px;
    height:24px;
  } 
  .ant-pagination.mini .ant-pagination-item {
    min-width: 35px;
    min-height: 35px;
    /* height:35px; */
    margin: 0;
    line-height: 35px;
  }
  .pagination, .ant-pagination, .ant-pagination-options-quick-jumper{
    font-size:14px !important;
  }

  .ant-pagination-item-active, .ant-pagination-item:focus-visible a {
    font-size:14px !important;  
  }
  .ant-pagination-item:hover {
    box-shadow: 0px 1px 4px rgba(0, 26, 90, 0.05) !important;
    border: 1px solid #e5e8ef !important;
    color: #546076 !important;
    font-size: 14px !important;
  
  }
 
  .ant-pagination-next,
  .ant-pagination-prev {
    min-width: 35px;
    min-height: 35px;
    margin: 0;
    line-height:35px;
  } 
  .ant-pagination-next button,
  .ant-pagination-prev button{
    min-width: 35px;
    min-height: 35px;
    margin: 0;
    line-height:35px;
  } 

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next{
    margin: 0!important;
    /* margin-right: 0rem !important; */
    margin-right:6px !important;
    margin-top:5px !important;
  }

  .pagination,
  .ant-pagination{
    display:flex;
    flex-wrap:wrap;
    margin-left:8px;
    margin-right: 8px;
    justify-content: flex-start;
    align-items: center;
  }
}