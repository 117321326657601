.qrScanner {
  border-radius: 16px;
  padding: 12px;
  /* background-color: red; */
}

.ant-tooltip-inner {
  padding: 0px !important;
}
.ant-tooltip-inner {
  border-radius: 14px !important;
  padding: 6px !important;
}

.qrScanner .qrHeader {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
}

.qrScanner .qrCopy {
  color: #546076;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid rgba(84, 96, 118, 0.2);
  border-radius: 8px;
  padding: 3px 0px;
  cursor: pointer;
}

.qrScanner .qrCopy:hover {
  background-color: #f5f5f573;
}

.qrScanner .qrCopy img {
  margin-right: 8px;
  height: 14px;
}

.qrScanner .qrOr {
  margin: 8px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #546076;
}

.qrScanner canvas {
  width: 154px !important;
  height: 154px !important;
  border: 1px solid rgba(84, 96, 118, 0.2);
  border-radius: 7.16627px;
  padding: 15px;
  margin-bottom: 8px;
}

.qrBottom {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  max-width: 100%;
  letter-spacing: 0.03em;
  color: #546076;
  max-width: 154px;
}
