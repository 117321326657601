.optionHead {
  font-weight: 600;
  margin-bottom: 11px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #546076;
  user-select:none;
}

.option {
  margin-bottom: 6px !important;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  user-select:none;

}

.moreOption{
  font-weight: 400;
  font-size: 14px;
  color: rgb(136, 136, 136);
  cursor: pointer;
  margin-top: 4px;
  color: #4385F5;
  font-weight: 500;
  user-select:none;

}

.clearAll{
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #7c7e8c;
  user-select:none;

}
.clearAll img{
  width: 14px;
  margin-right: 10px;
}
.clearAll span{
  color:#7c7e8c !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  user-select:none;
}
.disableClearAll{
  cursor: not-allowed;
  opacity: 0.6;
}

