footer {
  position: relative;
  background: #546076;
  display: flex;
  justify-content: center;
  color: #ffffff;
  height: 250px;
  width: 100%;
}
.footerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}


.text1 {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  font-weight: 200;
}
.Si_logo {
  margin-top: 9px;
  display: flex;
  align-items: center;
}
.Si_logo span {
  color:white;
  margin-left: 1rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.06em;
  
}

.text2 {
  margin-top: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.07em;
}

.Section2 {
  display: flex;
  /* height: 60%; */
  /* width: 25%; */
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
}
.Section2_tab {
  display: none;
  align-items: center;
  justify-content: center;
}

.Section2_col_1,
.Section2_tab_col_1 {
  margin-right: 3rem;
}
.Section2 p,
.Section2_tab p {
  color:white !important;
  text-decoration:none;
  cursor:pointer;
  overflow: hidden;
  white-space: nowrap;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.04em;
}
.Section3 {
  margin-bottom: 30px;
}
.Section3_text {
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.04em;
  margin-bottom: 1rem;
}

.handler_image {
  margin-right: 11.6px;
}
.handler_image:hover,
.handler_image_last:hover {
  opacity: 0.7;
}

.mobile_line {
  display: none;
}

.Space_for_icon {
  display: none;
}

@media (max-width: 1150px) {
  .footerBlock {
    width: 90%;
  }
  .handler_image,
  .handler_image_last {
    margin-bottom: 5px;
  }
}

@media (max-width: 972px) {
  footer {
    height: 340px;
  }
  .Section2 {
    display: none;
  }
  .Section2_tab {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .Section2_tab_col_1,
  .Section2_tab_col_2 {
    margin-right: 40px;
  }
 
  .footerBlock {
    /* padding: 1rem; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 85%;
    margin-left: 22px;
  }
  .Section3 {
    margin: 0;
  }
  .Section3_text {
    font-size: 14px;
  }

  .handler_image,
  .handler_image_last {
    width: 35px;
  }
}


@media (max-width: 425px) {
  footer {
    height: 434px;
    /* height: 500px; */
  }
  .Section2 {
    display: flex;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .Section2_tab {
    display: none;
    
  }
  .text1 {
    font-weight: 200;
    font-size: 14px;
    line-height: 17px;
  }

  .Si_image img {
    width: 32px;
    height: 32px;
  }

  .Si_logo span {
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
  }
  .Section2_col_1 {
    margin-right: 70px;
  }
}

@media (max-width: 390px) {
  .Space_for_icon {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 30px;
    background: #546076;
  }
}

@media (max-width: 320px) {
  .Section2_col_1 {
    margin-right: 30px;
  }
  .Si_logo span {
    font-size: 15px;
    margin-right: 2px;
  }
}
