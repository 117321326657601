.search {
  position: relative;
  height: 56px;
  width: 95%;
  z-index: 101;
}

.SearchBar {
  text-align: left;
  background: white;
  border: 1px solid #e5e8ef;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  outline: none;
  padding-left: 22px;
  padding-right: 24px;
  padding-top: 2px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #546076;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.search input::placeholder {
  opacity: 0.4;
  font-weight: normal;
}
.SearchBar:focus{
  transition: border 0.5s ease !important;
  border:solid #4385f5 1px !important;
  border-radius: 8px;
}

.searchIcon_filter {
  cursor: pointer;
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

.crossIcon,
.searchIcon {
  cursor: pointer;
  position: absolute;
  top: 27%;
  right: 28px;
}

.searchIcon {
  top: 30%;
  right: 14px;
}

.searchDropdown {
  position: absolute;
  top: 108%;
  list-style-type: none;
  list-style: none;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 00px;
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid rgba(0, 26, 90, 0.1);
  box-shadow: 0px 4px 16px 0px #001a5a24;
  border-radius: 12px;
  color: #546076;
  line-height: 24px;
  letter-spacing: 0.05em;
}

.searchDropdown li {
  text-transform: lowercase !important;
  cursor: pointer;
  padding: 10px;
  padding-left: 22px;
  border-radius: 12px;
}
.searchDropdown li::first-letter {
  text-transform: capitalize;
}

.searchDropdown li:hover {
  background: #e5e8ef;
}

.displayNone {
  display: none;
}
.emptyResult {
  color: #a9b0ba !important;
  text-align: left;
  padding-left: 24px;
  padding-right: 20px;
  padding-top: 17px;
  min-height: 57px;
}
.emptyResult_text {
  /* margin-top: 12px; */
  color: #a9b0ba !important;
}

/* media queries for responsive designs */

@media (max-width: 1024px) {
  .search {
    margin-left: 5px;
    width: calc(100% - 1rem);
    width: -moz-calc(100% - 1rem);
    width: -webkit-calc(100% - 1rem);
    width: -o-calc(100% - 1rem);
  }
}

@media (max-width: 950px) {
  .search {
    width: 80%;
    margin: 0 auto;
    height: 48px;
  }
}

@media (max-width: 768px) {
  .search {
    width: 86%;
    margin: 0;
    margin-right: 2rem !important;
    margin-left: 5rem;
  }
}

@media (max-width: 700px) {
  .search {
    width: 80%;
  }
}

@media (max-width: 425px) {
  .search {
    width: 95%;
    margin: 0rem;
    margin-left: 1rem;
  }
  .searchIcon {
    top: 30%;
    right: 10px;
  }
  .crossIcon {
    right: 21px;
  }
}
