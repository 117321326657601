.section-1 {
  display: flex;
  padding: 70px 110px;
  /* background: none; */
  /* z-index: -1; */
}

.section-1 h6 {
  margin-bottom: 0px !important;
}

.section-1-text-content {
  min-width: 50%;
  max-width: 600px;
  position: relative;
}

.section-1-text-content .illustration1 {
  position: absolute;
  bottom: -12%;
}

.section-1-heading {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 44px;
  letter-spacing: 0.03em;
  line-height: 57px;
  margin-bottom: 24px !important;
  color: #546076;
  position: relative;
}

.section-1-heading img {
  position: absolute;
  left: 20%;
  top: 30%;
}

.back-line {
  width: 150px !important;
}

.section-1-subheading {
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 16.9px;
  line-height: 125%;
  letter-spacing: 0.03em;
  color: #7c7e8c;
}

.section-1-animation {
  flex-grow: 1;
}

/* MEDIA QUERIES */
@media (max-width: 1250px) {
  .section-1-text-content .illustration1 {
    bottom: -24%;
  }
}
@media (max-width: 1218px) and (min-width:1101px) {
  .section-1-heading img{
    top: 24%;
    left: 24%;
  }
}


@media (max-width: 1100px) {
  .section-1 {
    flex-direction: column;
    padding: 50px 10%;
    text-align: center;
  }
  .section-1-text-content {
    min-width: 100% !important;
    margin-bottom: 60px;
  }
  .section-1-text-content .illustration1 {
    display: none;
  }
  .section-1-heading {
    font-weight: bold;
    font-size: 32px;
    line-height: 134%;
    letter-spacing: 0.06em;
    max-width: 600px;
    margin: auto;
  }
  .section-1-subheading {
    max-width: 555px;
    margin: auto;
    font-size: 20px;
  }
  .section-1-heading img {
    left: 16%;
    top: 46%;
  }
  .section-1-heading br {
    display: none;
  }
  .section-1-subheading br {
    display: none;
  }
  .section-1-animation {
    padding: 0 7%;
  }
}

@media (max-width: 711px) and (min-width:596px) {
  .section-1-heading img{
    left: 24%;
    top: 48%;
  }
}

@media (max-width: 596px) and (min-width:580px) {
  .section-1-heading img{
    left: 20%;
    top: 30%;
  }
}

@media (max-width: 580px) {
  .section-1 {
    flex-direction: column;
    padding: 40px 5%;
    text-align: center;
    text-align: start;
  }
  .section-1-heading {
    text-align: start;
    font-size: 24px;
    padding-left: 2%;
  }
  .section-1-subheading {
    font-size: 18px;
    padding-left: 2%;
  }
  .section-1-animation {
    padding: 0;
  }
  .section-1-heading img {
    left: 14%;
    top: 46%;
    width: 120px !important;
  }
}


@media (max-width: 405px) {
  .section-1-heading img {
    left: 16%;
    top: 32%;
    width: 120px !important;
  }
}
