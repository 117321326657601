.mobile_line {
  display: none;
}
.home {
  /* height: calc(100vh - 0px); */
  height: 580px;
  background-image: url(../../../assets/images/Landingbackground.svg);
  background-position: 0 -80px;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.home-top {
  padding-top: 60px;
  display: flex; /* background: red; */
  width: 850px;
  flex-direction: column;
  align-items: center;
}
.home-top-heading,
.home-top-subHeading,
.home-top-dropdownsSection-Button {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.home-top-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #546076;
}
.home-top-subHeading {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #546076;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.home-top-dropdownsSection {
  width: 380px;
}
.home-top-dropdownsSection-dropdown {
  display: flex;
}
.home-top-dropdownsSection-dropdown-course {
  margin-right: 1rem !important;
}
.home-top-dropdownsSection-Button {
  position:relative;
  cursor: pointer;
  margin-top: 1rem;
  height: 50px;
  width: 100%;
  background: #33d67b;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.4s;
}
.home-top-dropdownsSection-Button span {
  color: white;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  transition: all ease-in 0.1s;
}
.home-top-dropdownsSection-Button:hover {
  background: #2ce27e;
}
.Banner-downArrow {
  text-align: center;
  padding-top: 100px;
}
.bouncy {
  cursor: pointer;
  animation: bounce 3.5s ease-in-out infinite;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(
    1px 1px 1px rgba(0, 0, 0, 0.7)
  ); /* 0px 4px 4px 0px #0000001A; */
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(24px);
  }
}
@media (min-width: 1350px) {
  .home {
    background-image: none;
  }
}
@media (min-width: 1500px) {
  .home {
    height: 800px;
  }
  .home-top {
    margin-top: 60px;
    display: flex;
    width: 1050px;
  } /* .home-top-heading { font-size: 70px; line-height: 90px; letter-spacing: 0.04em; } .home-top-subHeading { font-size: 40px; line-height: 25px; margin-top: 2rem; margin-bottom: 4rem; } .home-top-dropdownsSection { width: 680px; height: 70px; } .home-top-dropdownsSection-dropdown { display: flex; } .home-top-dropdownsSection-dropdown-course { margin-right: 2rem !important; } .home-top-dropdownsSection-Button { margin-top: 2rem; height: 100px; width: 100%; background: #eafbf1; border-radius: 8px; } .home-top-dropdownsSection-Button span { font-size: 35px; line-height: 30px; }  */
}
@media (max-width: 1100px) {
  .home-top {
    padding-top: 100px;
  }
}
@media (min-width: 900px) {
  .home {
    background-position: 0 -10px;
  }
}

@media (max-width: 900px) {
  .home-top {
    padding-top: 75px;
  }

  .home {
    /* height: 115vh; */ /* height: 100vh; */
    /* background-image: url(../../../assets/images/TabBack.png);  */
    background-position: 0px;
    background: none;
  }
  .Banner-downArrow {
    display: none;
    padding-top: 0;
  }
  .home-top-heading {
    font-size: 45px;
    line-height: 67px;
  }
  .home-top {
    max-width: 700px;
  }
  .home-top-dropdownsSection {
    width: 350px;
  }
}
@media (max-width: 769px) {
  .home-top {
    padding-top: 90px;
  }
}

@media (max-width: 768px) {
  .home {
    height: 620px;
  }
  .home-top-heading {
    font-size: 40px;
    line-height: 67px;
  }
  .home-top {
    max-width: 500px;
  }
  .home-top-dropdownsSection {
    width: 350px;
  }
}
@media (max-width: 600px) {
  .home {
    /* background-position: 0px -60px; */
  }
}
@media (max-width: 550px) {
  .home {
    /* background-image: none; */
  }
  .Banner-downArrow {
    display: block;
    padding-top: 40px; /* margin-bottom: 80px; */
  }
  .home-top-heading {
    font-weight: bold;
    font-size: 27px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.04em;
  }
  .home-top-subHeading {
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    text-align: center;
    max-width: 300px;
  }
  .home-top {
    max-width: 400px;
  }
}
@media (max-width: 425px) {
  .Banner-downArrow img {
    width: 28px;
    height: 28px;
  }
  .mobile_line {
    position: absolute;
    bottom: 10px;
    display: block;
    width: 120px;
    height: 4px;
    background: #ffffff;
    border-radius: 36px;
  }
  .home {
    /* background-image: url(../../../assets/images/phoneBackground.svg); */
    background: none;

    display: flex;
    justify-content: center;
  }
  .home-top-heading {
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.04em;
  }
  .home-top-subHeading {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    text-align: center;
    max-width: 300px;
  }
  .home-top {
    max-width: 360px;
  }
  .home-top-dropdownsSection {
    max-width: 320px;
  }
}
@media (max-width: 380px) {
  .home-top-dropdownsSection {
    width: 280px;
  }
  .home-top {
    max-width: 300px;
  }
}
@media (max-width: 320px) {
  .home-top-dropdownsSection {
    width: 280px;
  }
  .home-top {
    max-width: 260px;
  }
}
@media (max-width: 306px) {
  .home-top-dropdownsSection {
    width: 250px;
  }
  .home-top {
    max-width: 260px;
  }
}
