.subLayout {
  background-image: url(../../../assets/images/TabBack.png);
  background-position: 0 -80px;
  background-size: 100%;
  background-repeat: no-repeat;
}

@media (max-width: 425px) {
  .subLayout {
    background-image: url(../../../assets/images/phoneBackground.svg);
    /* background-position: 0 -45px; */
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-position: 0 -45px;
    background-size: 100%;
  }
}
@media (max-width: 600px) {
  .subLayout {
    background-position: 0px -10px;
  }
}

@media (min-width: 900px) {
  .subLayout {
    background-image: none;
  }
}

@media (min-width: 1350px) {
  .subLayout {
    background-image: url(../../../assets/images/Landingbackground.svg) !important;
    /* background-position: 0 -80px;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 10;
    display: flex;
    justify-content: center; */
  }
}
