.Menu,
.BranchMenu {
  position:fixed !important;
  background: #ffffff !important;
  border: 1px solid #e5e8ef !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 16px rgba(0, 26, 90, 0.14) !important;
  border-radius: 12px !important;
  padding: 5px !important;
  /* width: 100% !important; */
  min-width:inherit !important;
}
.BranchMenu {
  padding: 0px !important;
  padding-bottom: 7px !important;
}

.MenuItem,
.SelectedMenuItem {
  font-family: Gilroy !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.03em !important;
  color: #546076 !important;
  border-radius: 4px !important;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.SelectedMenuItem {
  background: #d3e3fd !important;
  color: #4385f5 !important;
}
.MenuItem :hover {
  background: #e6e6e6 !important;
  opacity: 0.5 !important;
}

.Filter {
  display: flex !important;
  flex-direction: column !important;
}

.Filter span:nth-child(1) {
  color: #bec4cb;
}

.Filter span:nth-child(2) {
  color: #546076 !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 60px;
}
.Filter_branch {
  width: 120px !important;
}
.Filter span:nth-child(1),
.Filter span:nth-child(2) {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.branchSubCaption {
  color: #546076 !important;
}

.Dropdown,
.BranchDropdown {
  /* change for responsive design */
  /* position:relati!important; */
  background: white;
  z-index:2 !important;
  margin-right: 1rem !important;
  border: 1px solid rgba(0, 26, 90, 0.1) !important;
  border-radius: 8px !important;
  padding: 0px !important;
  padding-left: 1rem !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  /* padding-right: 45px !important; */
  cursor: pointer;
  padding-right: 10px !important;
  width: 60%;
  min-width: 124px;
  transition: border 0.5s ease !important;
}
.BranchDropdown {
  width: 100%;
  min-width: 174px;
}


.Dropdown:hover,
.BranchDropdown:hover{
  border:solid #4385f5 1px !important;
} 

.Dropdown_filter {
  display: flex !important;
  justify-content: space-between !important;
  min-height: 56px !important;
  align-items: center;
  z-index:2 !important;
}
.DownOutlined {
  font-weight: 600;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  transition: all ease 0.4s !important;
  transition-delay: 300ms !important;
}

.upOutlined {
  transform: rotate(180deg);
  transition: all ease 0.4s !important;
}

@media (min-width: 768px) {
  .Filter span:nth-child(2) {
    font-size: 14px;
  }
  .BranchDropdown {
    min-width: 150px;
  }
  .Dropdown {
    min-width: 100px;
  }
}

@media (max-width: 950px) {
  .Dropdown_filter {
    min-height: 48px !important;
  }
  .Dropdown,
  .BranchDropdown {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
