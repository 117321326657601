.section-3 {
  display: flex;
  padding: 70px 110px;
}

.section-3 h6 {
  margin-bottom: 0px !important;
}

.section-3-text-content {
  min-width: 50%;
  max-width: 600px;
  position: relative;
}

.section-3-text-content .illustration3{
  position: absolute;
  left: 5%;
  bottom: -28%;  
  animation: Rota_tion 4s ease infinite; 
}

@keyframes Rota_tion {
  0%,100%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(150deg);
  }
}


.section-3-heading {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 44px;
  letter-spacing: 0.03em;
  line-height: 57px;
  margin-bottom: 24px !important;
  color: #546076;
  position: relative;
}

.section-3-heading img {
  position: absolute; 
  left: 0%;
  top: 100%;
}

.back-line {
  width: 150px !important;
}

.section-3-subheading {
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 16.9px;
  line-height: 125%;
  letter-spacing: 0.03em;
  color: #7c7e8c;
}

.section-3-animation {
  flex-grow: 1;
}









/* MEDIA QUERIES */
@media (max-width: 1226px) {
  .section-3-text-content .illustration3{
    bottom: -38%;
  }
}
@media (max-width: 1100px) {
  .section-3 {
    flex-direction: column;
    padding: 50px 10%;
    text-align: center;
  }
  .section-3-text-content {
    min-width: 100% !important;
    margin-bottom: 60px;
  }
  .section-3-text-content .illustration3{
    display: none;
  }
  .section-3-heading {
    font-weight: bold;
    font-size: 32px;
    line-height: 134%;
    letter-spacing: 0.06em;
    max-width: 600px;
    margin: auto;
  }
  .section-3-subheading{
    max-width: 555px;
    margin: auto;
    font-size: 20px;
  }
  .section-3-heading img{
    left: 70%;
    top: 100%;
  }
  .section-3-heading br{
    display: none;
  }
  .section-3-subheading br{
    display: none;
  }
  .section-3-animation{
    padding: 0 7%;
  }
}


@media (max-width: 615px) {
  .section-3-heading img{
    left: 35%;
    top: 100%;
  }
}


@media (max-width: 580px) {
  .section-3 {
    flex-direction: column;
    padding: 40px 5%;
    text-align: center;
    text-align: start;
  }
  .section-3-heading{
    text-align: start;
    font-size: 24px;
    padding-left: 2%;
  }
  .section-3-subheading{
    font-size: 18px;
    padding-left: 2%;
  }
  .section-3-animation{
    padding: 0;
    transform: translateX(-16%);
  }
  .section-3-heading img{
    left: 54%;
    top: 100%;
    width: 120px !important;
  }
}

@media (max-width: 523px) {
  .section-3-heading img{
    left: 60%;
    top: 100%;
    width: 120px !important;
  }
}

@media (max-width: 471px) {
  .section-3-heading img{
    left: 66%;
    top: 100%;
    width: 120px !important;
  }
}

@media (max-width: 419px) {
  .section-3-heading img{
    left: 0%;
    top: 100%;
    width: 120px !important;
  }
}

@media (max-width: 327px) {
  .section-3-heading img{
    left: 30%;
    top: 100%;
    width: 120px !important;
  }
}