.displayArea {
  display: flex;
  min-height: 91.2vh;
  padding: 16px 40px;
  margin-top: 80px;
  background: #FCFCFC;;
}

.displayArea .sideBar {
  background-color: #ffffff;
  /* border: 1px solid rgba(0, 26, 90, 0.1); */
  box-shadow: 0px 1px 4px rgba(0, 26, 90, 0.05);
  border-radius: 16px;
  padding: 16px 24px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ant-dropdown-menu {
  display: flex;
  flex-direction: column;
}

.ant-checkbox + span {
  white-space: nowrap;
}

.ant-checkbox-wrapper {
  margin-left: 0px !important;
}
.ant-checkbox {
  z-index: 0 !important;
}

.emptyImgContainer {
  margin: auto;
}

@media (max-width: 1000px) {
  .displayArea .sideBar {
    display: none;
  }

  .displayArea {
    padding: 24px 32px;
  }
}
@media (max-width: 950px) {
  .displayArea {
    margin-top: 136px;
  }
}

@media (max-width: 425px) {
  .displayArea {
    margin-top: 185px;
  }
}
@media (max-width: 660px) {
  .displayArea {
    padding: 0;
  }
}
