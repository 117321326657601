.logo {
  margin-left: 5rem;
  white-space: nowrap;
  color: #546076;
  font-size: 24px;
  font-style: italic;
}

.logo_image {
  margin-right: 0.7rem;
}

.navbarHeading {
  margin-right: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feature_Text {
  cursor: pointer;
  position: relative;
  margin-right: 2.5rem;
  color: #546076;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.04em;
  user-select: none;
}

.getMobile {
  cursor: pointer;
  user-select: none;
  background: #4385f5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  transition: 0.6s;
}

.getMobile span {
  overflow: none;
  white-space: nowrap;
  color: white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.003em;
}
.getMobile span::after {
  content: "Get Mobile app";
}
.feature_Text:after {
  content: "";
  color: rgba(0, 26, 90, 0.08);
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

.feature_Text:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.feature_Text:active {
  color: rgb(0, 0, 0);
}

.getMobile:hover {
  background: #3d71cc;
}

/* 
@media (min-width: 1500px) {
  .logo_image {
    margin-right: 0.7rem;
    width: 40px;
  }
  .logo img:nth-last-child(1) {
    width: 120px;
  }

  .navbarHeading {
    margin-right: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .feature_Text {
    font-size: 40px;
    line-height: 25px;
    letter-spacing: 0.04em;
  }

  .getMobile {
    border-radius: 10px;
  }

  .getMobile span {
    overflow: none;
    white-space: nowrap;
    color: white;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0.003em;
  }
} */

@media (max-width: 768px) {
  .feature_Text {
    display: none;
  }
  .getMobile {
    border-radius: 4px;
    padding: 8px 12px;
  }
  .getMobile span {
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
  }
  .getMobile span::after {
    content: "Mobile app";
  }
  .navbar {
    box-shadow: inset 0px -1px 0px #e5e8ef;
  }
  .logo {
    margin-right: 5rem;
  }

  .navbarHeading {
    margin-right: 5rem;
  }

  .getMobile:hover {
    background: #4385f5;
  }
}

@media (max-width: 425px) {
  .navbar {
    height: 65px;
  }

  .logo {
    margin-left: 2rem;
  }

  .logo_image {
    margin-right: 0.4rem;
  }

  .navbarHeading {
    margin-right: 1rem;
  }
  .getMobile span {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
  }
}

@media (max-width: 530px) {
  .logo {
    margin-right: 2rem;
  }

  .navbarHeading {
    margin-right: 2rem;
  }
}

@media (max-width: 310px) {
  .logo {
    margin: 10px;
  }

  .navbarHeading {
    margin-right: 20px;
  }
}
