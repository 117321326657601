.List {
  display: flex;
  color: #546076;
  flex-grow: 2;
  padding: 18px 16px;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  font-family: Gilroy;
  font-style: normal;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 26, 90, 0.05);
  font-weight: 500;
  border-radius: 12px;
  text-transform: lowercase;
  text-transform: capitalize;
}

.LoadingList{
  width: 100%;
  height: 56px;
  margin-bottom: 16px;
}

.List:hover {
  border: 2px solid #e5e8ef;
  padding: 18px 16px;
}

.List_section1 {
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: lowercase;
  padding-right: 16px;
}
.List_section1::first-letter {
  text-transform: uppercase;
}

.List .List_section2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 45%;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: right;
}

.List .List_section3 {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.List .List_section4 {
  display: flex;
  justify-content: space-between;
  width: 20%;
}

.List_section2 {
  color: #a9b0ba;
}

.List .List_section4 .Icon:hover {
  background-color: #edf0f5;
  border-radius: 6px;
}

.List .List_section4 .Icon {
  padding: 11px;
  cursor: pointer;
  /* border-radius: 3px; */
}

.List .List_section4 img {
  width: 16.9px;
  height: 16.9px;
  cursor: pointer;
}












/* CSS FOR MOBILE VIEW */
.MobileList {
  display: none;
  flex-direction: row;
  color: #546076;
  /* flex-grow: 2; */
  padding: 18px 24px;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  font-family: Gilroy;
  font-style: normal;
  background: #ffffff;
  border: 1px solid #e5e8ef;
  font-weight: 500;
}
.LoadingMobileList{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 18px 24px;
  border: 1px solid #e5e8ef;
  background: #ffffff;
}

.MobileList .verticalSection {
  flex-grow: 4;
}

.MobileList .bottomSection {
  color: #a9b0ba;
  width: 100%;
}

.MobileList .bottomSection span {
  margin-right: 24px;
}

.MobileList .icons {
  display: flex;
  justify-content: space-between;
  width: 60px;
}

.MobileList .icons img {
  width: 16.8px;
}

.MobileList .mobPaperType {
  display: none;
}

@media screen and (max-width: 500px) {
  .MobileList .paperType {
    display: none;
  }
  .MobileList .mobPaperType {
    display: inline;
    font-weight: 600;
    margin-left: 5px;
  }
}

@media screen and (max-width: 660px) {
  .MobileList {
    margin-bottom: 1.5px;
    border-radius: 0;
    border: none;
    height: fit-content;
  }
  .MobileList .icons {
    display: flex;
    justify-content: space-between;
    min-width: 70px !important;
    margin-left: 20px;
  }
}

@media screen and (max-width: 850px) {
  .List {
    display: none;
  }
  .MobileList {
    display: flex;
  }
}

@media screen and (max-width: 1150px) {
  .List .List_section2 {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    min-width: 50%;
  }
}

/*
.List {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  max-width: 1016px;
  padding: 18px 24px;
  width: 100%;
  height: 56px;
  font-family: Gilroy;
  font-style: normal;
  background: #ffffff;
  border: 1px solid #e5e8ef;
  border-radius: 12px;
}

.List_section1 {
  color: #546076;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  margin-left: 30px;
}

.List_section2 {
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.List_section2 span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.03em;
  color: #a9b0ba;
  margin: 0px 20px;
}

.List_section2 img {
  width: 17px;
  height: 17px;
  margin: 0px 16px;
} */
