.line {
  background-image: url("../../../assets/images/bgLineD.jpg");
  background-repeat: no-repeat;
  /* z-index: 1000 !important; */
  background-position: 47.7% 520px;
  /* object-fit: cover; */
  background-size: 57%;
  user-select:none;
}

@media (max-width: 1000px) {
  .line {
    background-image: none;
  }
}

@media(min-width:1500px){
  .line{
    background-position: 47.7% 591px;
  }

}
