.scrollToTop {
  position: fixed;
  bottom: 2rem;
  right: 40px;
  animation: fadeIn 200ms ease-in-out 1s both;
  display: flex;
  align-items: center;
  z-index: 999;
}

.fadeAnimate {
  animation: fadeIn 200ms ease-in-out 1s both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.downloadContainer {
  display: flex;
  align-items: center;
  background: #20242d;
  box-shadow: 0px 1px 15px rgba(0, 26, 90, 0.1);
  border-radius: 16px;
  color: white;
  padding: 7px 22px;
  margin-right: 1rem;
}

.downloadContainer img {
  height: 20px;
  margin-right: 14px;
}

.downloadContainer .textContent {
  width: 120px;
  line-height: 140%;
}

.downloadContainer .downloadButton {
  background: #33d67b;
  border-radius: 6px;
  font-size: 12.9px;
  padding: 7px 15px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ArrowUp {
  padding: 13.2px;
  cursor: pointer;
  background: #20242d;
  box-shadow: 0px 1px 15px rgba(0, 26, 90, 0.1);
  opacity: 0.85 !important;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: 12px;
  width: 65.06px; */
}

.ArrowUp img{
  /* height: 20px;
  width: 20px; */
}

/* MEDIA QUERIES */
@media (max-width: 768px) {
  .scrollToTop {
    /* right: 50%; */
    /* left: 30px; */
    right: 30px;
    /* transform: translateX(50%); */
  }
}
@media (max-width: 500px) {
  .downloadContainer {
    display: none;
  }
}
