.home-top-records {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5% 12.9%;
  flex-wrap: wrap;
  /* background: white; */
  /* margin-top: 50px; */
}

.home-top-records .backFrame {
  position: absolute;
  right: 0;
  top: 0;
}

.home-top-records-downloads {
  position: relative;
  padding: 30px;
  box-shadow: 0px 1px 40px rgba(0, 26, 90, 0.05);
  border-radius: 16px;
  background-color: #ffffff;
  min-height: 219px;
  min-width: 264px;
}

.home-top-records-downloads-image {
  margin-bottom: 2rem;
}

.home-top-records-downloads-image img {
  width: 52px;
  height: 52px;
}

.home-top-records-downloads-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 41px;
  color: #546076;
  margin-bottom: 0.2rem;
}
.home-top-records-downloads-subHeading {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.03em;
  color: #546076;
}
@media (max-width: 1160px) {
  .home-top-records {
    padding: 5% 9%;
    justify-content: space-around;
  }
  .home-top-records-downloads {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    padding: 31px 24px;
    min-height: auto;
    min-width: 272px;
    max-height: 120px !important;
  }
  .home-top-records-downloads-image {
    margin-bottom: 0px;
    margin-right: 16px;
  }
  .home-top-records-downloads-heading {
    margin-bottom: 4px;
  }
}
@media (max-width: 1120px) {
  .home-top-records {
    padding: 5% 13%;
  }
}
@media (max-width: 780px) {
  .home-top-records-downloads {
    width: 100%;
  }
  .home-top-records {
    /* margin-top: 150px; */
  }
}
@media (max-width: 580px) {
  .home-top-records {
    padding: 60px 28px;
    padding-top: 0;
  }
  .home-top-records-downloads-image img {
    width: 36.8px;
    height: 40px;
  }
  .home-top-records-downloads-image {
    margin-right: 24px;
  }
  .home-top-records-downloads {
    /* padding: 2px; */
    max-height: 90px !important;
  }
  .home-top-records-downloads-heading {
    font-size: 22px;
    margin-bottom: 0px;
  }
  .home-top-records-downloads-subHeading {
    font-size: 18.9px;
  }
  .home-top-records .backFrame {
    max-height: 90px;
  }
}
