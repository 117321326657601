.navbar {
  overflow: hidden !important;
}

.Filter_navbar {
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Gilroy";
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 26, 90, 0.08);
  position: fixed;
  z-index: 1000 !important;
  width: 100%;
  height: 80px;
  background: white;
}

.logo {
  margin-left: 3rem;
  margin-right: 5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  position: absolute;
  top: 0;
  left: 0;
  /* background: white; */
}
.logo_mobile_text {
  display: none;
}

.logo_image {
  width: 1rem;
  height: 1.5rem;
}
.logo_byte {
  width: 19.42px;
  height: 26.67px;
}

.logo_text {
  text-decoration: none !important;
  color: #546076;
  font-size: 18px;
  font-style: italic;
  margin-left: 8px;
  align-self: center;
}

.all_filters {
  display: flex;
  width: 40%;
  /* background: blue; */
  margin-left: 15rem;
}
.SearchBarSection {
  width: 100%;
  /* background: red; */
}

.sidebarTab {
  display: none;
}
.sideArrow,
.blackSpace {
  display: none;
}
.blackSpace {
  display: none;
}

@media (max-width: 1024px) {
  .all_filters {
    width: 90%;
  }
}

/* for sidebar tablet */

@media (max-width: 1000px) {
  .all_filters {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .sidebarTab {
    display: flex;
  }
  .logo {
    margin-left: 2rem;
    margin-top: 1.5rem;
  }
  .logo_image {
    width: 20px;
    height: 100%;
  }
  .logo_text {
    display: none;
  }
  .all_filters {
    /* width: 10%; */
    margin-left: 6rem;
  }
}

@media (max-width: 950px) {
  .Filter_navbar {
    height: 136px;
    /* background: yellow; */
    align-items: flex-start;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .all_filters {
    position: relative;
    display: flex;
    margin: 0 auto;
    margin-right: 4rem;
    margin-left: 2rem;
    width: 90%;
    padding-bottom: 8px;
  }

  .logo_image {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0.4rem;
  }

  .logo {
    z-index: 2;
  }
  .logo_text {
    display: none;
  }
  .logo {
    margin-left: 2rem;
    margin-right: 0;
    margin-top: 18px;
  }

  .SearchBarSection {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    /* margin-left: 0.5rem; */
    /* margin-right: 1rem; */
  }
  .sideArrow {
    display: block;
    position: fixed;
    right: 1.5rem;
    top: 5.3rem;
    box-shadow: 4px 2px 8px 8px white;
    background: white;
    z-index: 100;
  }
}

@media (max-width: 425px) {
  .blackSpace {
    display: block;
    width: 20px;
    opacity: 0;
  }
  .Filter_navbar {
    flex-direction: column;
    height: 186px;
    flex-direction: column-reverse;
    justify-content: flex-start;
    box-shadow: 0px -1px 0px 0px #001a5a1a inset;
  }
  .logo {
    width: 96%;
    margin: 0;
    margin-left: 0.7rem;
    margin-top: 0rem;
    padding-right: 1rem;
    background: white;
    align-items: center;
    height:auto;
  }
  .logo_image {
    margin-bottom: 0rem !important;
    margin-left: 0.5rem;
    margin-right: 0rem;
  }
  .logo_byte {
    width: 12.63px;
    height: 19.5px;
  }

  .logo_mobile_text {
    /* margin-right: 1rem !important; */
    font-weight: 600;
    margin-top: 1rem;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.03em;
    color: #4385F5;
    font-family: Gilroy;
    background: #E7EFFE;
    padding-left:16px;
    padding-right:16px;
    padding-top:8px;
    padding-bottom:8px;
    border-radius: 8px;

  }

  .logo_text {
    display: inline-block !important;
    text-decoration: none !important;
    width: 68px;
    height: 15px;
    margin-left: 0.5rem;
    align-self: center;
    justify-content: center !important;
  }

  .SearchBarSection {
    margin-bottom: 12px;
    margin-top: 0rem !important;
    padding-right: 1rem;
    /* background: white; */
  }
  .all_filters {
    padding:0;
    width: 100%;
    margin-left: 1rem;
    margin-bottom: 8px;
  }
  .sideArrow {
    top: 8.65rem;
  }
}
