/* .Menu {
  background: #ffffff !important;
  border: 1px solid rgba(0, 26, 90, 0.1) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 16px rgba(0, 26, 90, 0.14) !important;
  border-radius: 12px !important;
  padding: 7px !important;
  width: 100% !important;
} */


.Filter {
  display: flex !important;
  flex-direction: column !important;
}

.Filter span:nth-child(1) {
  color: #bec4cb;
}

.Filter span:nth-child(2) {
  color: #687487;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
}

.Filter span:nth-child(1),
.Filter span:nth-child(2) {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  background: white;
}

.Filter span:nth-child(2) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 140px;
}


@media (min-width: 768px) {
  .Filter span:nth-child(2) {
    font-size: 14px;
  }

  .Dropdown,
  .branchDropDown {
    min-width: 100px;
  }
}
@media (max-width: 900px) {
  .Filter span:nth-child(2) {
    width: 120px;
  }
}
@media(max-width:570px){
  .Menu,.noMenu{
    max-height:200px;
  }
}
@media (max-width: 425px) {
  .Filter span:nth-child(2) {
    width: 95px;
  }
}

@media (max-width: 380px) {
  .Filter span:nth-child(2) {
    width: 80px;
  }
}




/* ***********************NEW CSS************************* */
.dropdownHeader:first-child{
  margin-right: 1rem;
}
.dropdownHeader{
  position: relative;
  z-index: 10;
  border: 1px solid rgba(0, 26, 90, 0.1) !important;
  border-radius: 8px !important;
  padding: 0px !important;
  padding-left: 15px !important;
  padding-right: 45px !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  cursor: pointer;
  padding-right: 10px !important;
  width: 100%;
  background: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* overflow: hidden; */
  transition: border 0.5s ease !important;
}
.dropdownHeader:hover{
  border:solid #4385f5 1px !important;
}
.Dropdown_filter {
  display: flex !important;
  justify-content: space-between !important;
  min-height: 48px !important;
  align-items: center;
}
.upOutlined {
  transform: rotate(180deg);
  transition: all ease 0.4s !important;
}
.DownOutlined {
  transform: rotate(0deg);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  transition: all ease 0.4s !important;
  transition-delay: 300ms !important;
}
.Menu,.noMenu{
  position: absolute;
  left: 0;
  top: 56px;
  background: #ffffff !important;
  border: 1px solid rgba(0, 26, 90, 0.1) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 16px rgba(0, 26, 90, 0.14) !important;
  border-radius: 12px !important;
  padding: 7px !important;
  width: 100% !important;
  transition: max-height 0.34s ease,opacity 0.34s ease;
  max-height:300px;
  overflow-y:auto;
}
.Menu::-webkit-scrollbar {
  background-color: #fff;
  width: 13.6px;
}
.noMenu{
  max-height: 0px !important;
  overflow: hidden;
   border: none !important; 
  opacity: 0;
  box-shadow: none !important;
  /* visibility:none; */
  padding: 0px !important;

}
.MenuItem,.SelectedMenuItem {
  font-family: Gilroy !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.03em !important;
  color: #546076 !important;
  border-radius: 4px !important;
  margin-bottom: 0.1rem !important;
  margin-top: 0.1rem !important;
  padding: 12px 0.5rem 1rem 12px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.SelectedMenuItem {
  background: #d3e3fd !important;
  color: #4385f5 !important;
}
.MenuItem:hover {
  background: #e6e6e68a !important;
  /* opacity: 0.5 !important; */
  color: #546076 !important;
}

.MenuContent{
  display:block;
}
.hide{
  display:block;
}