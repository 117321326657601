.PaperSection {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Papers {
  width: 100%;
}

@media (max-width: 1000px) {
  .PaperSection {
    margin-left: 0px;
  }
}

@media (max-width:500px){
  .PaperSection {
    background:white;
  }
}