@font-face {
  font-family: "Gilroy";
  src: url("assets/fonts/Gilroy-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("assets/fonts/Gilroy-Regular.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("assets/fonts/Gilroy-Semibold.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("assets/fonts/Gilroy-Bold.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("assets/fonts/Gilroy-Semibold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Bold.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-SemiBold.ttf");
  font-weight: 400;
  font-style: normal;
}
* {
  font-family: "Gilroy";
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  background-color:#fff;
  width:16px
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color:#fff
}
::-webkit-scrollbar-track:hover {
  background-color:#f4f4f4
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:5px solid #fff
}
::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
/* ::-webkit-scrollbar-button {display:none} */

/* div box */
/* body{background-color:#babac0} */
 
.LandingNavbar,
.LandingNavbarboxShadow {
  font-family: "Gilroy";
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  z-index: 1000;
  box-shadow: none;
  transition: box-shadow 1s;
}
.LandingNavbarboxShadow {
  box-shadow: 0px -1px 0px 0px #001a5a14 inset;
}

.ant-checkbox-inner {
  border: 2px solid #7f8798 !important;
  /* margin-right: 5px !important; */
}

.ant-checkbox-checked .ant-checkbox-inner {
  border: none !important;
}
.ant-checkbox {
  color: none !important;
}
/* .ant-pagination-options-size-changer.ant-select{
  display: none !important;
} */

span.ripple {
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 400ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

.ant-dropdown {
  position: fixed !important;
}

@keyframes ripple {
  to {
    transform: scale(1);
    opacity: 0;
  }
}

@media (max-width: 425px) {
  .LandingNavbar,
  .LandingNavbarboxShadow {
    height: 65px;
  }
  ::-webkit-scrollbar {
    background-color:#fff;
    width:12px;
  }
}
