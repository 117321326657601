.option {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.Checkbox {
  display: flex;
  margin-bottom: calc(11.79px - 5px) !important;
  margin-left: 0px !important;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #546076 !important;
  padding-left: 1rem !important;
  padding-bottom: 5px !important;

  /* background: lightskyblue; */
}

.branchColor {
  color: #4385f5;
}

.clear {
  margin-top: 10px;
  padding-bottom: 9px !important;
  padding-left: 1rem;
  margin-bottom: 0px;
  cursor: pointer;
  display: flex;
}

.clear span {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #7c7e8c;
  padding-left: 15px;
  margin-top: 5px;
}

/* .clear span::before {
  content: "x";
  margin-right: 15px;
  font-size: 20px;
} */
.branchSpacing,
.branchColor {
  padding-left: 5px !important;
}

.break {
  margin: 0;
  margin-bottom: 6px;
  margin-right: 17px;
  margin-left: 17px;
  background: #e7e8e9;
  height: 1px;
  min-width: 70%;
}
.dropdownOptions {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}
.dropdownOptions::-webkit-scrollbar {
  background-color: #fff;
  width: 12.8px;
}

.disableClearAll{
  cursor: not-allowed;
  opacity: 0.6;
}